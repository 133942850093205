import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'react-emotion'
import { Avatar, Table } from 'antd'
import numeral from 'numeral'

import cwl from '../../images/cwl_logo.png'
import arsenal from '../../images/maps/arsenal_icon.png'
import frequency from '../../images/maps/frequency_icon.png'
import gridlock from '../../images/maps/gridlock_icon.png'
import hacienda from '../../images/maps/hacienda_icon.png'
import payload from '../../images/maps/payload_icon.png'
import seaside from '../../images/maps/seaside_icon.png'

const MapButton = ({ isActive, ...props }) => {
  const StyledAvatar = styled(Avatar)`
    img {
      filter: ${() => (isActive ? 'none' : 'grayscale(100%)')};
    }
  `
  return <StyledAvatar {...props} />
}

MapButton.propTypes = {
  isActive: PropTypes.bool,
}

MapButton.defaultProps = {
  isActive: false,
}

class MapPicker extends Component {
  state = {
    selectedIdx: 0,
    selectedTeam: 0,
  }

  handleClickFactory = idx => {
    return () => this.setState({ selectedIdx: idx })
  }

  handleClickFactoryTabs = idx => {
    return () => this.setState({ selectedTeam: idx })
  }

  render() {
    const {
      handleClickFactory,
      handleClickFactoryTabs,
      props: { series },
      state: { selectedIdx, selectedTeam },
    } = this

    const matches = [
      { name: 'Overall', map: 'Overall', mode: 'Overall', teams: series.teams },
    ].concat(series.matches)

    const columns = [
      {
        title: '',
        dataIndex: 'name',
        key: 'name',
        onCell: () => {
          return {
            style: {
              fontWeight: 'bold',
              textAlign: 'left',
            },
          }
        },
        width: 85,
        fixed: 'left',
      },
      {
        title: 'K/D',
        dataIndex: 'stats.killDeathRatio',
        key: 'killDeathRatio',
        onHeaderCell: () => {
          return {
            style: {
              textAlign: 'center',
            },
          }
        },
        onCell: () => {
          return {
            style: {
              textAlign: 'center',
            },
          }
        },
        render: text => numeral(text).format('0.00'),
      },
      {
        title: '+/-',
        dataIndex: 'stats.plusMinus',
        key: 'plusMinus',
        onHeaderCell: () => {
          return {
            style: {
              textAlign: 'center',
            },
          }
        },
        onCell: () => {
          return {
            style: {
              textAlign: 'center',
            },
          }
        },
        render: text => numeral(text).format('+0'),
      },
      {
        title: 'K-D',
        dataIndex: 'stats',
        key: 'kills-deaths',
        onHeaderCell: () => {
          return {
            style: {
              textAlign: 'center',
            },
          }
        },
        onCell: () => {
          return {
            style: {
              textAlign: 'center',
            },
          }
        },
        render: (text, record) => (
          <span>
            {record.stats.kills}-{record.stats.deaths}
          </span>
        ),
      },
      {
        title: 'Score',
        dataIndex: 'stats.score',
        key: 'score',
        onHeaderCell: () => {
          return {
            style: {
              textAlign: 'center',
            },
          }
        },
        onCell: () => {
          return {
            style: {
              textAlign: 'center',
            },
          }
        },
      },
      {
        title: 'Damage',
        dataIndex: 'stats.damage',
        key: 'damage',
        onHeaderCell: () => {
          return {
            style: {
              textAlign: 'center',
            },
          }
        },
        onCell: () => {
          return {
            style: {
              textAlign: 'center',
            },
          }
        },
      },
    ]

    if (matches[selectedIdx].mode === 'Hardpoint') {
      columns.push({
        title: 'Hill Time',
        dataIndex: 'stats.hillTime',
        key: 'hillTime',
        onHeaderCell: () => {
          return {
            style: {
              textAlign: 'center',
            },
          }
        },
        onCell: () => {
          return {
            style: {
              textAlign: 'center',
            },
          }
        },
      })
    }

    if (matches[selectedIdx].mode === 'Search & Destroy') {
      columns.push({
        title: 'FB:FD',
        dataIndex: 'stats',
        key: 'fb-fd',
        onHeaderCell: () => {
          return {
            style: {
              textAlign: 'center',
            },
          }
        },
        onCell: () => {
          return {
            style: {
              textAlign: 'center',
            },
          }
        },
        render: (text, record) => (
          <span>
            {record.stats.sndFirstbloods}:{record.stats.sndFirstdeaths}
          </span>
        ),
      })
    }

    if (matches[selectedIdx].mode === 'Control') {
      columns.push({
        title: 'FB:FD',
        dataIndex: 'stats',
        key: 'fb-fd',
        onHeaderCell: () => {
          return {
            style: {
              textAlign: 'center',
            },
          }
        },
        onCell: () => {
          return {
            style: {
              textAlign: 'center',
            },
          }
        },
        render: (text, record) => (
          <span>
            {record.stats.ctrlFirstbloods}:{record.stats.ctrlFirstdeaths}
          </span>
        ),
      })
    }

    if (matches[selectedIdx].mode === 'Overall') {
      const player = columns.shift()
      columns.unshift({
        title: 'aKD',
        dataIndex: 'stats.adjustedKillDeathRatio',
        key: 'aKd',
        onHeaderCell: () => {
          return {
            style: {
              textAlign: 'center',
            },
          }
        },
        onCell: () => {
          return {
            style: {
              textAlign: 'center',
            },
          }
        },
        render: text => numeral(text).format('0.00'),
      })
      columns.unshift(player)
    }

    return (
      <Fragment>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          {[{ name: 'Overall', map: 'Overall', mode: 'Overall', teams: series.teams }]
            .concat(series.matches)
            .map(({ map, mode }, idx) => {
              const isActive = idx === selectedIdx
              const MODES = {
                'Search & Destroy': 'SnD',
                Hardpoint: 'HP',
                Control: 'CTL',
                Overall: 'OVR',
              }
              switch (map) {
                case 'Arsenal':
                  return (
                    // eslint-disable-next-line
                    <div onClick={handleClickFactory(idx)} key={`${mode}-${map}-${idx}`}>
                      <MapButton src={arsenal} shape="square" size="large" isActive={isActive} />
                      <span
                        style={{
                          display: 'block',
                          width: '100%',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          color: isActive ? '#1890ff' : '',
                        }}
                      >
                        {MODES[mode]}
                      </span>
                    </div>
                  )
                case 'Frequency':
                  return (
                    // eslint-disable-next-line
                    <div onClick={handleClickFactory(idx)} key={`${mode}-${map}-${idx}`}>
                      <MapButton src={frequency} shape="square" size="large" isActive={isActive} />
                      <span
                        style={{
                          display: 'block',
                          width: '100%',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          color: isActive ? '#1890ff' : '',
                        }}
                      >
                        {MODES[mode]}
                      </span>
                    </div>
                  )
                case 'Gridlock':
                  return (
                    // eslint-disable-next-line
                    <div onClick={handleClickFactory(idx)} key={`${mode}-${map}-${idx}`}>
                      <MapButton src={gridlock} shape="square" size="large" isActive={isActive} />
                      <span
                        style={{
                          display: 'block',
                          width: '100%',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          color: isActive ? '#1890ff' : '',
                        }}
                      >
                        {MODES[mode]}
                      </span>
                    </div>
                  )
                case 'Hacienda':
                  return (
                    // eslint-disable-next-line
                    <div onClick={handleClickFactory(idx)} key={`${mode}-${map}-${idx}`}>
                      <MapButton src={hacienda} shape="square" size="large" isActive={isActive} />
                      <span
                        style={{
                          display: 'block',
                          width: '100%',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          color: isActive ? '#1890ff' : '',
                        }}
                      >
                        {MODES[mode]}
                      </span>
                    </div>
                  )
                case 'Payload':
                  return (
                    // eslint-disable-next-line
                    <div onClick={handleClickFactory(idx)} key={`${mode}-${map}-${idx}`}>
                      <MapButton src={payload} shape="square" size="large" isActive={isActive} />
                      <span
                        style={{
                          display: 'block',
                          width: '100%',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          color: isActive ? '#1890ff' : '',
                        }}
                      >
                        {MODES[mode]}
                      </span>
                    </div>
                  )
                case 'Seaside':
                  return (
                    // eslint-disable-next-line
                    <div onClick={handleClickFactory(idx)} key={`${mode}-${map}-${idx}`}>
                      <MapButton src={seaside} shape="square" size="large" isActive={isActive} />
                      <span
                        style={{
                          display: 'block',
                          width: '100%',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          color: isActive ? '#1890ff' : '',
                        }}
                      >
                        {MODES[mode]}
                      </span>
                    </div>
                  )
                default:
                  return (
                    // eslint-disable-next-line
                    <div onClick={handleClickFactory(idx)} key={`${mode}-${map}-${idx}`}>
                      <MapButton src={cwl} shape="square" size="large" isActive={isActive} />
                      <span
                        style={{
                          display: 'block',
                          width: '100%',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          color: isActive ? '#1890ff' : '',
                        }}
                      >
                        {MODES[mode]}
                      </span>
                    </div>
                  )
              }
            })}
        </div>

        <div
          style={{
            display: 'flex',
            width: '100%',
            paddingTop: '20px',
            paddingLeft: '20px',
            paddingRight: '20px',
            borderBottom: '1px solid #e8e8e8',
            marginBottom: '10px',
          }}
        >
          <div
            style={{
              width: '50%',
              marginRight: '10px',
              textAlign: 'center',
              paddingBottom: '10px',
              borderBottom: selectedTeam ? '' : '2px solid #1890ff',
            }}
            onClick={handleClickFactoryTabs(0)}
            onKeyPress={handleClickFactoryTabs(0)}
            role="button"
            tabIndex="-1"
          >
            <span
              style={{ color: selectedTeam ? 'rgba(0,0,0,0.65)' : 'black', fontWeight: 'bold' }}
            >
              {matches[selectedIdx].teams[0].name}
            </span>
          </div>
          <div
            style={{
              width: '50%',
              marginLeft: '10px',
              textAlign: 'center',
              paddingBottom: '10px',
              borderBottom: selectedTeam ? '2px solid #1890ff' : '',
            }}
            onClick={handleClickFactoryTabs(1)}
            onKeyPress={handleClickFactoryTabs(1)}
            role="button"
            tabIndex="-1"
          >
            <span
              style={{ color: selectedTeam ? 'black' : 'rgba(0,0,0,0.65)', fontWeight: 'bold' }}
            >
              {matches[selectedIdx].teams[1].name}
            </span>
          </div>
        </div>
        <Table
          size="small"
          scroll={{ x: true }}
          pagination={false}
          columns={columns}
          dataSource={matches[selectedIdx].teams[selectedTeam].players.concat([
            { name: 'Totals', stats: matches[selectedIdx].teams[selectedTeam].stats },
          ])}
          rowKey={row => row.name}
        />
      </Fragment>
    )
  }
}

MapPicker.propTypes = {
  series: PropTypes.shape({}).isRequired,
}

export default MapPicker
