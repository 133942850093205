import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import numeral from 'numeral'

const columns = [
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
    onCell: () => {
      return {
        style: {
          fontWeight: '600',
          textAlign: 'right',
        },
      }
    },
  },
  {
    title: 'aKD',
    dataIndex: 'stats.adjustedKillDeathRatio',
    key: 'adjustedKillDeathRatio',
    onHeaderCell: () => {
      return {
        style: {
          textAlign: 'center',
        },
      }
    },
    onCell: () => {
      return {
        style: {
          textAlign: 'center',
        },
      }
    },
    render: text => numeral(text).format('0.00'),
  },
  {
    title: '+/-',
    dataIndex: 'stats.plusMinus',
    key: 'plusMinus',
    onHeaderCell: () => {
      return {
        style: {
          textAlign: 'center',
        },
      }
    },
    onCell: () => {
      return {
        style: {
          textAlign: 'center',
        },
      }
    },
    render: text => numeral(text).format('+0'),
  },
  {
    title: 'Score',
    dataIndex: 'stats.score',
    key: 'score',
    onHeaderCell: () => {
      return {
        style: {
          textAlign: 'center',
        },
      }
    },
    onCell: () => {
      return {
        style: {
          textAlign: 'center',
        },
      }
    },
  },
]

const flippedColumns = [
  {
    title: 'Score',
    dataIndex: 'stats.score',
    key: 'score',
    onHeaderCell: () => {
      return {
        style: {
          textAlign: 'center',
        },
      }
    },
    onCell: () => {
      return {
        style: {
          textAlign: 'center',
        },
      }
    },
    render: text => numeral(text).format('0'),
  },
  {
    title: '+/-',
    dataIndex: 'stats.plusMinus',
    key: 'plusMinus',
    onHeaderCell: () => {
      return {
        style: {
          textAlign: 'center',
        },
      }
    },
    onCell: () => {
      return {
        style: {
          textAlign: 'center',
        },
      }
    },
    render: text => numeral(text).format('+0'),
  },
  {
    title: 'aKD',
    dataIndex: 'stats.adjustedKillDeathRatio',
    key: 'adjustedKillDeathRatio',
    onHeaderCell: () => {
      return {
        style: {
          textAlign: 'center',
        },
      }
    },
    onCell: () => {
      return {
        style: {
          textAlign: 'center',
        },
      }
    },
    render: text => numeral(text).format('0.00'),
  },
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
    onCell: () => {
      return {
        style: {
          fontWeight: '600',
          textAlign: 'left',
        },
      }
    },
  },
]

const Summary = ({ dataSource, isFlipped, ...props }) => (
  <Table
    {...props}
    pagination={false}
    columns={isFlipped ? flippedColumns : columns}
    dataSource={dataSource}
  />
)

Summary.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFlipped: PropTypes.bool,
}

Summary.defaultProps = {
  isFlipped: false,
}

export default Summary
