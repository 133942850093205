import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { injectGlobal } from 'emotion'
import styled from 'react-emotion'
import { Spin, Table, Tabs } from 'antd'
import Media from 'react-media'
import numeral from 'numeral'
import pathToRegexp from 'path-to-regexp'

import Layout from '../../../components/layout'
import Summary from '../../../components/tables/series/summary'

import MapPicker from '../../../components/mobile/MapPicker'

const { TabPane } = Tabs

// eslint-disable-next-line no-unused-expressions
injectGlobal`
  ul.ant-select-dropdown-menu {
    margin-left: 0px;
  }

  .striped-leaderboard-row {
    background-color: rgb(250, 250, 250);
  }

  .ant-table-thead {
    font-size: 12px;
    white-space: nowrap;
  }

  .ant-table-row {
    font-size: 12px;
  }

  .ant-table td { white-space: nowrap; }

  @media screen and (min-width: 600px) {
    .ant-table-fixed-columns-in-body > div {
      opacity: 0.0;
    }
  }
`

const MobileContainer = styled.div`
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    padding: 50px;
    width: 1024px;
  }

  @media screen and (max-width: 1023px) {
    padding: 20px;
    max-width: 100vw;
  }

  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
  .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-body
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th {
    padding: 0px 20px;
  }
`

const ScoresContainer = styled.div`
  display: flex;
  margin: 0 auto;
  padding-bottom: 20px;

  @media screen and (min-width: 1024px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
`
const Desktop = props => <Media {...props} query="(min-width: 769px)" />
const Mobile = props => <Media {...props} query="(max-width: 768px)" />

const LeftRightSummary = styled.div`
  width: 100%;
  padding-left: 0.25em;
  padding-right: 0.25em;

  @media screen and (min-width: 1024px) {
    flex: 1 0;
  }
`

const SummaryScore = styled.div`
  padding-left: 0.25em;
  padding-right: 0.25em;
  @media screen and (min-width: 1024px) {
    flex: 0 1;
  }
`

const CenteredTabs = styled(Tabs)`
  .ant-tabs-nav-scroll {
    display: flex;
    flex: 1 auto;
    justify-content: center;
  }
`

class Scores extends Component {
  state = {
    series: {},
    loading: true,
  }

  componentDidMount = async () => {
    const { location } = this.props
    const [event, series] = location.pathname
      .match(pathToRegexp('/events/:eventId/series/:seriesId'))
      .slice(1)

    const res = await fetch(`/.netlify/functions/fetch-series?event=${event}&series=${series}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    this.setState({ series: await res.json(), loading: false })
  }

  render = () => {
    const { location } = this.props
    const { series, loading } = this.state

    return (
      <Layout location={location}>
        <MobileContainer>
          <div style={{ textAlign: 'center', paddingBottom: '6px' }}>
            <h4>CWL Fort Worth</h4>
          </div>
          {loading && (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Spin tip="Loading..." />
            </div>
          )}
          {!loading && series.score[0].wins !== 3 && series.score[1].wins !== 3 && (
            <div style={{ color: 'red', textAlign: 'center', paddingBottom: '6px' }}>
              <span
                style={{ fontWeight: 'bold', fontSize: '18px' }}
                role="img"
                aria-label="live indicator"
              >
                •
              </span>{' '}
              LIVE
            </div>
          )}
          {!loading && (
            <Desktop>
              {matches =>
                matches && (
                  <Fragment>
                    <ScoresContainer>
                      <LeftRightSummary>
                        <h1 style={{ textAlign: 'left' }}>{series.teams[0].name}</h1>
                        <Summary dataSource={series.teams[0].players} isFlipped />
                      </LeftRightSummary>
                      <SummaryScore>
                        <h1 style={{ textAlign: 'center' }}>
                          {series.score[0].wins} - {series.score[1].wins}
                        </h1>
                        <Table
                          pagination={false}
                          columns={[
                            {
                              title: '',
                              dataIndex: 'left',
                              key: 'left',
                              onCell: () => {
                                return {
                                  style: {
                                    textAlign: 'center',
                                  },
                                }
                              },
                              render: (text, { mode }) => {
                                if (!text) {
                                  return '-'
                                }
                                if (mode === 'HP' && text === 250)
                                  return (
                                    <span style={{ fontWeight: 'bold', color: '#1890ff' }}>
                                      {text}
                                    </span>
                                  )
                                if (mode === 'SnD' && text === 6)
                                  return (
                                    <span style={{ fontWeight: 'bold', color: '#1890ff' }}>
                                      {text}
                                    </span>
                                  )
                                if (mode === 'CTL' && text === 3)
                                  return (
                                    <span style={{ fontWeight: 'bold', color: '#1890ff' }}>
                                      {text}
                                    </span>
                                  )
                                return text
                              },
                            },
                            {
                              title: 'Modes',
                              dataIndex: 'mode',
                              key: 'mode',
                              onHeaderCell: () => {
                                return {
                                  style: {
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                  },
                                }
                              },
                              onCell: () => {
                                return {
                                  style: {
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                  },
                                }
                              },
                            },
                            {
                              title: '',
                              dataIndex: 'right',
                              key: 'right',
                              onCell: () => {
                                return {
                                  style: {
                                    textAlign: 'center',
                                  },
                                }
                              },
                              render: (text, { mode }) => {
                                if (!text) {
                                  return '-'
                                }
                                if (mode === 'HP' && text === 250)
                                  return (
                                    <span style={{ fontWeight: 'bold', color: '#1890ff' }}>
                                      {text}
                                    </span>
                                  )
                                if (mode === 'SnD' && text === 6)
                                  return (
                                    <span style={{ fontWeight: 'bold', color: '#1890ff' }}>
                                      {text}
                                    </span>
                                  )
                                if (mode === 'CTL' && text === 3)
                                  return (
                                    <span style={{ fontWeight: 'bold', color: '#1890ff' }}>
                                      {text}
                                    </span>
                                  )
                                return text
                              },
                            },
                          ]}
                          dataSource={(() => {
                            const modes = ['HP', 'SnD', 'CTL', 'HP', 'SnD']
                            const left = series.score[0].scores
                              .concat([undefined, undefined, undefined, undefined, undefined])
                              .slice(0, 5)
                            const right = series.score[1].scores
                              .concat([undefined, undefined, undefined, undefined, undefined])
                              .slice(0, 5)
                            return modes.map((mode, idx) => ({
                              left: left[idx],
                              mode,
                              right: right[idx],
                            }))
                          })()}
                        />
                      </SummaryScore>
                      <LeftRightSummary>
                        <h1 style={{ textAlign: 'right' }}>{series.teams[1].name}</h1>
                        <Summary dataSource={series.teams[1].players} />
                      </LeftRightSummary>
                    </ScoresContainer>
                    <CenteredTabs>
                      {!loading &&
                        [
                          {
                            map: 'All Maps',
                            mode: 'Overall',
                            teams: [series.teams[0], series.teams[1]],
                          },
                        ]
                          .concat(series.matches)
                          .map(match => {
                            const { map, mode } = match

                            const columns = [
                              {
                                title: '',
                                dataIndex: 'name',
                                key: 'name',
                                onCell: () => {
                                  return {
                                    style: {
                                      fontWeight: 'bold',
                                      textAlign: 'left',
                                    },
                                  }
                                },
                              },
                              {
                                title: 'K/D',
                                dataIndex: 'stats.killDeathRatio',
                                key: 'killDeathRatio',
                                onHeaderCell: () => {
                                  return {
                                    style: {
                                      textAlign: 'center',
                                    },
                                  }
                                },
                                onCell: () => {
                                  return {
                                    style: {
                                      textAlign: 'center',
                                    },
                                  }
                                },
                                render: text => numeral(text).format('0.00'),
                              },
                              {
                                title: '+/-',
                                dataIndex: 'stats.plusMinus',
                                key: 'plusMinus',
                                onHeaderCell: () => {
                                  return {
                                    style: {
                                      textAlign: 'center',
                                    },
                                  }
                                },
                                onCell: () => {
                                  return {
                                    style: {
                                      textAlign: 'center',
                                    },
                                  }
                                },
                                render: text => numeral(text).format('+0'),
                              },
                              {
                                title: 'K-D',
                                dataIndex: 'stats',
                                key: 'kills-deaths',
                                onHeaderCell: () => {
                                  return {
                                    style: {
                                      textAlign: 'center',
                                    },
                                  }
                                },
                                onCell: () => {
                                  return {
                                    style: {
                                      textAlign: 'center',
                                    },
                                  }
                                },
                                render: (text, record) => (
                                  <span>
                                    {record.stats.kills}-{record.stats.deaths}
                                  </span>
                                ),
                              },
                              {
                                title: 'Score',
                                dataIndex: 'stats.score',
                                key: 'score',
                                onHeaderCell: () => {
                                  return {
                                    style: {
                                      textAlign: 'center',
                                    },
                                  }
                                },
                                onCell: () => {
                                  return {
                                    style: {
                                      textAlign: 'center',
                                    },
                                  }
                                },
                              },
                              {
                                title: 'Damage',
                                dataIndex: 'stats.damage',
                                key: 'damage',
                                onHeaderCell: () => {
                                  return {
                                    style: {
                                      textAlign: 'center',
                                    },
                                  }
                                },
                                onCell: () => {
                                  return {
                                    style: {
                                      textAlign: 'center',
                                    },
                                  }
                                },
                              },
                            ]

                            if (match.mode === 'Hardpoint') {
                              columns.push({
                                title: 'Hill Time',
                                dataIndex: 'stats.hillTime',
                                key: 'hillTime',
                                onHeaderCell: () => {
                                  return {
                                    style: {
                                      textAlign: 'center',
                                    },
                                  }
                                },
                                onCell: () => {
                                  return {
                                    style: {
                                      textAlign: 'center',
                                    },
                                  }
                                },
                              })
                            }

                            if (match.mode === 'Search & Destroy') {
                              columns.push({
                                title: 'FB:FD',
                                dataIndex: 'stats',
                                key: 'fb-fd',
                                onHeaderCell: () => {
                                  return {
                                    style: {
                                      textAlign: 'center',
                                    },
                                  }
                                },
                                onCell: () => {
                                  return {
                                    style: {
                                      textAlign: 'center',
                                    },
                                  }
                                },
                                render: (text, record) => (
                                  <span>
                                    {record.stats.sndFirstbloods}:{record.stats.sndFirstdeaths}
                                  </span>
                                ),
                              })
                            }

                            if (match.mode === 'Control') {
                              columns.push({
                                title: 'FB:FD',
                                dataIndex: 'stats',
                                key: 'fb-fd',
                                onHeaderCell: () => {
                                  return {
                                    style: {
                                      textAlign: 'center',
                                    },
                                  }
                                },
                                onCell: () => {
                                  return {
                                    style: {
                                      textAlign: 'center',
                                    },
                                  }
                                },
                                render: (text, record) => (
                                  <span>
                                    {record.stats.ctrlFirstbloods}:{record.stats.ctrlFirstdeaths}
                                  </span>
                                ),
                              })
                            }

                            if (match.mode === 'Overall') {
                              const player = columns.shift()
                              columns.unshift({
                                title: 'aKD',
                                dataIndex: 'stats.adjustedKillDeathRatio',
                                key: 'aKd',
                                onHeaderCell: () => {
                                  return {
                                    style: {
                                      textAlign: 'center',
                                    },
                                  }
                                },
                                onCell: () => {
                                  return {
                                    style: {
                                      textAlign: 'center',
                                    },
                                  }
                                },
                                render: text => numeral(text).format('0.00'),
                              })
                              columns.unshift(player)
                            }

                            return (
                              <TabPane
                                tab={[
                                  <div
                                    key={mode}
                                    style={{
                                      textAlign: 'center',
                                      color: '#000',
                                      fontWeight: 'heavy',
                                    }}
                                  >
                                    {mode}
                                  </div>,
                                  <div key={map} style={{ textAlign: 'center' }}>
                                    {map}
                                  </div>,
                                ]}
                                key={`${mode}-${map}`}
                              >
                                <ScoresContainer>
                                  <div style={{ width: '100%' }}>
                                    <h4>{match.teams[0].name}</h4>
                                    <Table
                                      pagination={false}
                                      columns={columns}
                                      dataSource={match.teams[0].players.concat([
                                        { name: 'Totals', stats: match.teams[0].stats },
                                      ])}
                                    />
                                  </div>
                                </ScoresContainer>
                                <ScoresContainer>
                                  <div style={{ width: '100%' }}>
                                    <h4>{match.teams[1].name}</h4>
                                    <Table
                                      pagination={false}
                                      columns={columns}
                                      dataSource={match.teams[1].players.concat([
                                        { name: 'Totals', stats: match.teams[1].stats },
                                      ])}
                                    />
                                  </div>
                                </ScoresContainer>
                              </TabPane>
                            )
                          })}
                    </CenteredTabs>
                  </Fragment>
                )
              }
            </Desktop>
          )}
          {!loading && (
            <Mobile>
              {matches =>
                matches && (
                  <Fragment>
                    <ScoresContainer>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '0 7px',
                        }}
                      >
                        <h2 style={{ textAlign: 'left' }}>{series.teams[0].name}</h2>
                        <h2 style={{ textAlign: 'right' }}>{series.score[0].wins}</h2>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '0 7px',
                        }}
                      >
                        <h2 style={{ textAlign: 'left' }}>{series.teams[1].name}</h2>
                        <h2 style={{ textAlign: 'right' }}>{series.score[1].wins}</h2>
                      </div>
                      <SummaryScore>
                        <Table
                          pagination={false}
                          columns={[
                            {
                              title: series.teams[0].name,
                              dataIndex: 'left',
                              key: 'left',
                              onCell: () => {
                                return {
                                  style: {
                                    textAlign: 'center',
                                  },
                                }
                              },
                              render: (text, { mode }) => {
                                if (!text) {
                                  return '-'
                                }
                                if (mode === 'HP' && text === 250)
                                  return (
                                    <span style={{ fontWeight: 'bold', color: '#1890ff' }}>
                                      {text}
                                    </span>
                                  )
                                if (mode === 'SnD' && text === 6)
                                  return (
                                    <span style={{ fontWeight: 'bold', color: '#1890ff' }}>
                                      {text}
                                    </span>
                                  )
                                if (mode === 'CTL' && text === 3)
                                  return (
                                    <span style={{ fontWeight: 'bold', color: '#1890ff' }}>
                                      {text}
                                    </span>
                                  )
                                return text
                              },
                            },
                            {
                              title: 'Modes',
                              dataIndex: 'mode',
                              key: 'mode',
                              onHeaderCell: () => {
                                return {
                                  style: {
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                  },
                                }
                              },
                              onCell: () => {
                                return {
                                  style: {
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                  },
                                }
                              },
                            },
                            {
                              title: series.teams[1].name,
                              dataIndex: 'right',
                              key: 'right',
                              onCell: () => {
                                return {
                                  style: {
                                    textAlign: 'center',
                                  },
                                }
                              },
                              render: (text, { mode }) => {
                                if (!text) {
                                  return '-'
                                }
                                if (mode === 'HP' && text === 250)
                                  return (
                                    <span style={{ fontWeight: 'bold', color: '#1890ff' }}>
                                      {text}
                                    </span>
                                  )
                                if (mode === 'SnD' && text === 6)
                                  return (
                                    <span style={{ fontWeight: 'bold', color: '#1890ff' }}>
                                      {text}
                                    </span>
                                  )
                                if (mode === 'CTL' && text === 3)
                                  return (
                                    <span style={{ fontWeight: 'bold', color: '#1890ff' }}>
                                      {text}
                                    </span>
                                  )
                                return text
                              },
                            },
                          ]}
                          rowKey={row => row.left + row.mode + row.right}
                          dataSource={(() => {
                            const modes = ['HP', 'SnD', 'CTL', 'HP', 'SnD']
                            const left = series.score[0].scores
                              .concat([undefined, undefined, undefined, undefined, undefined])
                              .slice(0, 5)
                            const right = series.score[1].scores
                              .concat([undefined, undefined, undefined, undefined, undefined])
                              .slice(0, 5)
                            return modes.map((mode, idx) => ({
                              left: left[idx],
                              mode,
                              right: right[idx],
                            }))
                          })()}
                        />
                      </SummaryScore>
                    </ScoresContainer>
                    <MapPicker {...this.state} />
                  </Fragment>
                )
              }
            </Mobile>
          )}
        </MobileContainer>
      </Layout>
    )
  }
}

Scores.propTypes = {
  location: PropTypes.shape({}).isRequired,
}

export default Scores
